import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Hero from "../components/homecomponents/Hero";
import { BookMeeting } from "../components/homecomponents/BookMeeting";
import { Lead } from "../components/homecomponents/Lead";
import { ClientLogos } from "../components/homecomponents/ClientLogos";
import { GetInspired } from "../components/homecomponents/GetInspired";
import { OurServices } from "../components/homecomponents/OurServices";
import { Reviews } from "../components/homecomponents/Reviews";
import { Portfolio } from "../components/Portfolio";
import { FooterForm } from "../components/FooterForm";
import { Footer } from "../components/Footer";
import { useState } from "react";
import { useLocation } from 'react-router-dom';
import person from '../assets/images/person.jpg'


export const Home = () => {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  useEffect(() => {
    // Simulate a 5-second loading delay
    setTimeout(() => {
      setIsLoading(false);
    }, 5000);
  }, [location]);
  return (
    <React.Fragment>
      <>
        {isLoading ? (
          <div className="loader_">
            <video autoPlay muted className=" w-100 video-1 ">
              <source
                src={require("../assets/images/loader.mp4")}
                type="video/mp4"
              />
            </video>
          </div>
        ) : (
          <>
            <Navbar />
            <section className="relative banner-home-bg video-main-banner">
              <div className="absolute  flex items-center justify-center items-center h-full w-full">
                <Hero />
              </div>
              <div className="ewd-offic-video ">
                <video autoPlay muted loop className=" w-100 video-1 ">
                  <source
                    src={require("../assets/video/ewd-video.mp4")}
                    type="video/mp4"
                    />
                </video>
              </div>
            </section>
            <BookMeeting />
            <Lead />
            <ClientLogos
              title="Our Clients"
              desc="Our data-driven approach excites us to help our clients thrive by evergreen support"
              bgColor="bg-1e1e1e"
            />
            <GetInspired desc="In today's fast-paced and competitive digital marketplace, it is more important than ever to have a robust digital presence. That is where we come in. " />
            <OurServices />
            <Reviews />
            <Portfolio />
            <section className="mainMarginTop maindPadding">
              <h2 className="text-center text-4xl bold-font pb-12">Our Partner</h2>
              <div className="flex justify-end book-meeting-flex relative relativeMain">
                <div className=" book-meeting-home-col-1 ">
                  <div className="bg-black book-meeting-home lg:px-8 px-4 lg:py-12 py-7 mt-20 ">
                    <h1 className="text-white book-meeting-title lg:text-4xl sm:text-2xl text-2xl md:text-3xl  font-black">
                      Digital Strategist
                    </h1>
                    <p className="text-white leading-6 pt-6">
                      JM is an experienced Consultant, Coach and IT Strategist both in
                      Corporate and Business World. JM have handled big development projects
                      handling project teams which includes developers who JM have guided
                      and coached as she’s once a developer herself.
                      <br />
                      <br />
                      Additionally, JM is a well-sought Digital Expert, Motivational
                      Speaker, Trainer, and Digital Transformation Coach on a mission to
                      help trainers, speakers, coaches, and business leaders build authentic
                      digital brands by providing Digital Coaching and Automation to prepare
                      them to lead better &amp; have a positive impact to their own
                      communities.
                    </p>
                    <div className="pt-12 mt-12" />
                  </div>
                </div>
                <div className="book-meeting-home-col-2  relative mt-0">
                  <img
                    src={person}
                    alt="not found"
                    className="w-full"
                  />
                  <a
                    href="https://www.facebook.com/jmdgenerous"
                    target="_blank"
                    rel="noreferrer"
                    className="relative relativeJM"
                  >
                    <h4 className="text-center aboutJM">More About JM</h4>
                  </a>
                </div>
              </div>
            </section>

            <FooterForm desc="We believe in transforming generations through innovation and displaying the remarkable services. We would love to add engaging and refreshing spotlight at your brand." />
            <Footer />
          </>
        )}
      </>
    </React.Fragment>
  );
};
