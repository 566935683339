import React from "react";
import Navbar from "../components/Navbar";
import { Footer } from "../components/Footer";
const Store = () => {
  return (
    <>

      <section className=" flex items-center justify-center ">
        <Navbar navClass="bg-1D1C1C" />
        <div className="container mt-[140px] m-auto pb-[50px]">
          <h1 className="text-center text-[40px] font-[600]">Shop By Product</h1>
          <div className="lg:grid grid-cols-4 w-full px-[15px] flex-wrap gap-[20px] pb-[90px] mt-[60px] justify-center">
            <div className="">
              <a href="https://amzn.to/4iwyeXV">
                <div className="product-card">
                  <div className="product-card__image">
                    <img
                      src="https://m.media-amazon.com/images/I/81X8mQB7x-L._AC_SX679_.jpg"
                      alt="Red Nike Shoes"
                    />
                  </div>
                  <div className="product-card__info">
                    <h2 className="product-card__title font-bold text-lg leading-tight line-clamp-2"> VetIQ Glucosamine Hip & Joint Supplement for Dogs, 180 Soft Chews, Dog Joint Support Supplement with MSM and Krill, Dog Health Supplies Large & Small Breed, Chicken Flavored Chewables </h2>
                    <p className="product-card__description text-sm leading-relaxed line-clamp-2">
                      VetIQ Maximum Strength Hip Joint soft chews for dogs is a daily, joint health supplement offered in bite size, soft Chewables. With the natural chicken flavor, it won't be hard to coax your dog to take his Glucosamine. Stiff and sore joints come with ages for mankind and man's best friend. VetIQ Maximum Strength Hip Joint for Dogs is a new daily supplement formulated to support healthy joint function and flexibility. Maximum Strength Hip Joint is supplemented with Glucosamine HCL, Methysonylmethane (MSM) and other ingredients that help support cartilage and healthy connective tissue. Comprehensive joint health supplement Antioxidants and Omega3 fatty acids Soft Chewables are a tasty treat 180 natural chicken flavor soft chew that dogs love Made in the U. S. A.
                    </p>
                    <div className="product-card__price-row">
                      <span className="product-card__price">$18.90</span>
                      <button className="product-card__btn">Buy Now </button>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className=" lg:mt-[0] sm:mt-[20px] mt-[20px]">
              <a href="https://amzn.to/4kMDZ5k">
                <div className="product-card">
                  <div className="product-card__image">
                    <img
                      src="https://m.media-amazon.com/images/I/71UVgdOvzoL._AC_SX679_.jpg"
                      alt="Red Nike Shoes"
                    />
                  </div>
                  <div className="product-card__info">
                    <h2 className="product-card__title font-bold text-lg leading-tight line-clamp-2">  EHEYCIGA Orthopedic Dog Beds for Extra Large Dogs, Waterproof Memory Foam XL Dog Bed with Sides, Non-Slip and Egg-Crate Foam Big Dog Couch Bed with Washable Removable Cover, Grey  </h2>
                    <p className="product-card__description text-sm leading-relaxed line-clamp-2">
                      The EHEYCIGA orthopedic dog bed features memory foam and egg crate foam to provide even support and pressure relief. It offers a good balance of softness and support, making it particularly suitable for elderly dogs to alleviate joint pressure.
                    </p>
                    <div className="product-card__price-row">
                      <span className="product-card__price">$49.99</span>
                      <button className="product-card__btn">Buy Now </button>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className=" lg:mt-[0] sm:mt-[20px] mt-[20px]">
              <a href="https://amzn.to/4hivKv0">
                <div className="product-card">
                  <div className="product-card__image">
                    <img
                      src="https://m.media-amazon.com/images/I/71eYHT4wi1L._AC_SX679_.jpg"
                      alt="Red Nike Shoes"
                    />
                  </div>
                  <div className="product-card__info">
                    <h2 className="product-card__title font-bold text-lg leading-tight line-clamp-2">  Comotech 3PCS Dog Bath Brush | Dog Shampoo Brush | Dog Scrubber for Bath | Dog Bath Brush Scrubber | Dog Shower/Washing Brush with Adjustable Ring Handle for Short & Long Hair (Blue Blue Blue)
                    </h2>
                    <p className="product-card__description text-sm leading-relaxed line-clamp-2">
                      This dog shower brush set contains 3 PCS brushes with different functions, which can effectively solve your pet's bathing, hair removal, massage, grooming problems. Let your pet fall in love with bathing and enjoy bath time.The Best Bath Gifts For Pets!
                    </p>
                    <div className="product-card__price-row">
                      <span className="product-card__price">$8.97</span>
                      <button className="product-card__btn">Buy Now </button>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className=" lg:mt-[0] sm:mt-[20px] mt-[20px]">
              <a href="https://amzn.to/4kVhQBP">
                <div className="product-card">
                  <div className="product-card__image">
                    <img
                      src="https://m.media-amazon.com/images/I/71JX2D498cL._AC_SX679_.jpg"
                      alt="Red Nike Shoes"
                    />
                  </div>
                  <div className="product-card__info">
                    <h2 className="product-card__title font-bold text-lg leading-tight line-clamp-2">  Stainless Steel Cat Litter Box, Extra Large Litter Boxes for Big Cats, XL Metal Cat Litter Box with Lid, Anti-Urine Leakage, Non-Sticky, Include Cat Mat and Litter Scoop
                    </h2>
                    <p className="product-card__description text-sm leading-relaxed line-clamp-2">
                      URINE LEAKPROOF &GREAT FOR HIGH PEEING CAT: 6" high walls and stainless steel litter pan overlap the installation, retaining the urine in the cat litter box, while also preventing the litter from splashing when the cat digs, solving the problem of cat urine leakage and litter splashing.
                    </p>
                    <div className="product-card__price-row">
                      <span className="product-card__price">$59.99</span>
                      <button className="product-card__btn">Buy Now </button>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className=" lg:mt-[0] sm:mt-[20px] mt-[20px]">
              <a href="https://amzn.to/3XZyXbV">
                <div className="product-card">
                  <div className="product-card__image">
                    <img
                      src="https://m.media-amazon.com/images/I/71W0jdrSbdL._AC_SX679_.jpg"
                      alt="Red Nike Shoes"
                    />
                  </div>
                  <div className="product-card__info">
                    <h2 className="product-card__title font-bold text-lg leading-tight line-clamp-2">  AMOSIJOY Cat Sill Window Perch Sturdy Cat Hammock Window Seat with Cushion Bed Cover, Wood & Metal Frame for Large Cats, Easy to Adjust Cat Bed for Windowsill, Bedside, Drawer and Cabinet(Cushion Bed)

                    </h2>
                    <p className="product-card__description text-sm leading-relaxed line-clamp-2">
                      This cat window perch is upgraded with thickened fluffy cushion bed cover, which give cats more comfort and safety while sleeping on it. The cover is made of soft plush fabric and cotton, perfect for keeping warm. Let your cats feel more comfortable while lying in this resting seat. It is of Velcro tape design, easy to remove for wash.

                    </p>
                    <div className="product-card__price-row">
                      <span className="product-card__price">$29.99</span>
                      <button className="product-card__btn">Buy Now </button>
                    </div>
                  </div>
                </div>
              </a>
            </div>


          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Store;
